
import { defineComponent, ref, onMounted } from "vue";
// import ChannelDatatable from "@/components/kt-datatable/ChannelDataTable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Globals from "@/views/common/Globals.vue";
import { ElNotification } from "element-plus";
import { ElLoading } from 'element-plus'
import { TEMPLATES } from "@/helper/globalUrl"
// import Editor from '@tinymce/tinymce-vue'
import tinymce from 'tinymce';
import router from "@/router";
import { Field, Form } from "vee-validate";
import store from "@/store";
import { hideModal } from "@/core/helpers/dom";
import * as Yup from "yup";

export default defineComponent({
  name: "EmailConfiguration",
  mixins: [Globals],
  components: {
    Form,
    Field,
    // Editor,
    // ChannelDatatable
  },
  data() {
    return {
      show: false,
      eventName: "",
      eventId: "",
      emailTemplate: [] as any,
      emailBody: "",
      emailSubject: "",
      emailDisclaimer: "",
      emailBodyError: false,
      standardVariable: [] as any,
      toggleFlag: true,
      templateLength: 0,
      render: false,
      sdvar: ["CFNAME", "CMNAME", "CLNAME", "DNAME", "INAME", "FDNAME"],
      items: [],
      eventTemplateId: '',
      validation_errors: {
        subject:'',
        body: ''
      }
    };
  },

  mounted() {
    const standard_variable = localStorage.getItem('standard_variable');
    if (standard_variable) {
      this.items = JSON.parse(standard_variable);
    }
  },
  methods: {
    reset_validation_errors(){
      this.validation_errors.body = ""
      this.validation_errors.subject = ""
    },
    get_all_template_variables(template){
      const variable_list = this.standardVariable.map(item => item.variable)
      // console.log("this.standardVariable", this.standardVariable);
      const regex = /##(.*?)##/g;
      const matches = template.match(regex);
      let variable_str = ""
      if (matches) {
        matches.map(match => {
          console.log("variables-->>", match);
          match = match.replaceAll("##", "");
          if (!variable_list.includes(match)){
            variable_str = variable_str +" "+ match
          }
        })
        if (variable_str.length == 0){
          return false
        }
        return variable_str;
      } 
      return false;

    },
    validate_form(formData){
      this.reset_validation_errors();
      if (this.emailBody.length === 0 ) {
        this.validation_errors.body = "body field is required.";
      }
      if (this.emailSubject.length === 0){
        this.validation_errors.subject = "subject field is required.";
      }
      let is_error = this.get_all_template_variables(this.emailBody);
      if (is_error ){
        this.validation_errors.body = "body has invalid template variable"+ is_error;
      }
      is_error = this.get_all_template_variables(this.emailSubject);
      if (is_error){
        this.validation_errors.subject = "subject has invalid template variable"+ is_error;
      }

    },
    validate() {
      var emailStr = this.emailBody.replace(/<[^>]+>/g, '');
      if (this.emailBody.length === 0 || this.emailSubject.length === 0) {
        this.emailBodyError = true
      }
      else {
        this.emailBodyError = false
      }
    },
    setResult(result) {
      this.emailBody = this.emailBody.slice(0, this.emailBody.length - 5)
      this.emailBody = this.emailBody + result;
      this.isOpen = false;
    },
    async saveEmailConfigureData(formData) {
      this.validate_form(formData);
      // await this.validate_form().validate(formData, { abortEarly: false });
      // var emailStr = this.emailBody.replace(/<[^>]+>/g, '');
      
      formData.PCSConfigurationId = 1,
        formData.eventId = this.eventId,
        formData.isEnabled = true,
        formData.eventTemplate.body = this.emailBody
      formData.eventTemplate.subject = this.emailSubject
      formData.eventTemplate.disclaimer = this.emailDisclaimer

      if(this.validation_errors.subject.length === 0 && this.validation_errors.body.length === 0)
      {
        ApiService.patch(TEMPLATES + this.eventTemplateId, formData).then((data) => {
        hideModal(this.emailConfigurationModalRef);
        ElNotification({
          title: "Success",
          message: "Updated Successfully!",
          type: "success",
          position: "top-right",
        });
        this.$emit("callEvent", "");
      })
        .catch((error) => {
          console.log(error);
        });
      }
     


      // ApiService.post(TEMPLATES + this.eventTemplateId,formData).then((data) => {
      //       hideModal(this.emailConfigurationModalRef);
      //       this.$emit("callEvent", "");
      // })
      // .catch((error) => {
      //   console.log(error);
      // });


    },
    callEmailConfigureModal(id, name, template, variable, eventTemplateId) {
      // if ('email' in template){
      //   this.templateLength = 1
      // }
      // else{
      //   this.templateLength = 0
      // }
      console.log("in callEmailConfigureModal..", variable);
      this.eventId = id
      this.eventName = name
      // this.standard_variable_setup = variable
      this.standardVariable = variable
      this.items = JSON.parse(localStorage.getItem('standard_variable'))
      // if( Object.keys(template).length>0 && 'email' in template)
      // {
      this.emailBody = template.body
      this.emailSubject = template.subject
      this.eventTemplateId = eventTemplateId;
      this.emailDisclaimer = template.disclaimer ? template.disclaimer : ''
      // }
      // else
      // {
      // this.emailBody=""
      // this.emailSubject=""
      // this.emailDisclaimer=""
      // }

      this.render = true
    },
  },

  setup() {
    // const SUBJECT = /^[^-\s][a-zA-Z0-9_\s.\d@$!%*#?&/\\-]{4,}$/
    // const DISCLIMER = /^[^-\s][a-zA-Z0-9_.\d@$!%*#?&/\\-]{0,}$/
    // const formValidation = Yup.object().shape({
    //   emailSubject: Yup.string().matches(SUBJECT, "Please enter valid email subject").required().label("Email subject"),
    // });
    const init = ref({
      selector: 'textarea',
      height: 200,
      menubar: false,
      plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons code',
      imagetools_cors_hosts: ['picsum.photos'],
      // toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | code | fullscreen  preview save print | insertfile image media codesample | ltr rtl',
      autosave_ask_before_unload: true,
      autosave_interval: '5s',
      image_advtab: true,
      toolbar_sticky: false,
      image_caption: true,
      automatic_uploads: true,
      content_style: 'body { font-family:helvetica, arial, sans-serif; font-size:14px }'
    })
    const buttonRef = ref()
    const popoverRef = ref()
    const emailConfigurationModalRef = ref<null | HTMLElement>(null);
    const formData = ref({
      PCSConfigurationId: 1,
      eventId: 1,
      isEnabled: false,
      eventTemplate: {
        body: "",
        subject: "",
        disclaimer: ""
      }
    })
    const tableHeader = ref([
      {
        name: "Variable",
        key: "variable",
        sortable: true,
      },
      {
        name: "Variable Description",
        key: "variableDescription",
      },
    ]);

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Email Configuration", ["Setting"]);
    });

    return {
      tableHeader,
      formData,
      init,
      emailConfigurationModalRef,
      // formValidation
    };
  },
});
